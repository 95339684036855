<template>
  <div class="create-scheduling">
    <CForm
      ref="createMember"
      novalidate
      class="create-form"
    >
      <div class="form-content">
        <!--    FROM    -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.startLabel"
          xl-content="4"
          :toolTipText="descriptions.achievementAvailableFrom"
        >
          <template #content>
            <DatePicker
              :dateProp="startDate"
              @input="setStartDate"
              :placeholder="texts.createPage.schedulingStep.startPlaceholder"
              :isValid="fromValidate !== false"
              class="schedule-startDate"
              :disabledDate="disabledStartDate"
              :disabled="isDisabled"
            />
          </template>
        </ClFormRow>
        <!--     TILL   -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.endLabel"
          xl-content="4"
          :toolTipText="descriptions.till"
        >
          <template #content>
            <DatePicker
              :dateProp="endDate"
              @input="setEndDate"
              :placeholder="texts.createPage.schedulingStep.endPlaceholder"
              :disabledDate="disabledEndDate"
              :disabled="isDisabled"
            />
          </template>
        </ClFormRow>
        <!-- Schedule Type   -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.scheduleTypeLabel"
          xl-content="4"
          :toolTipText="descriptions.membersCanAchieveOn"
        >
          <template #content>
            <ClSelect
              class="text-capitalize"
              :options="occurrencesLimitOption"
              :placeholder="texts.createPage.schedulingStep.scheduleTypePlaceholder"
              :valueProp="formData.scheduleType"
              size="xxl"
              required
              :selectLabel="selectLabel"
              :invalidFeedback="texts.invalidFeedback"
              :disabled="isDisabled"
              @checkValue="scheduleTypeUpdate"
            />
          </template>
        </ClFormRow>
        <!--    When    -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.whenLabel"
          xl-content="5"
          v-if="isEveryVisible"
        >
          <template #content>
            <multiselect
              v-model="everyChecked"
              :options="every"
              label="label"
              track-by="value"
              :multiple="true"
              :selectLabel="selectLabel"
              :tagPlaceholder="tagPlaceholder"
              :preserve-search="true"
              :hide-selected="true"
              :disabled="isDisabled"
              class="custom-multi-select zq--multiselect"
            >
              <template #caret>
                <div></div>
              </template>
            </multiselect>
            <img
              src="../../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <!--  Schedule Occurrences Limit      -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.occurrencesLabel"
          v-if="isEveryVisible || formData.scheduleType === 'Repeatedly'"
        >
          <template #content>
            <CInput
              type="number"
              :placeholder="texts.createPage.schedulingStep.occurrencesPlaceholder"
              v-model.number="formData.scheduleOccurrencesLimit"
              add-input-classes="col-sm-12"
              min="1"
              step="1"
              @update:value="handleUpdateLimit"
              :disabled="isDisabled"
              :invalidFeedback="texts.invalidFeedback"
            />
          </template>
        </ClFormRow>
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {cloneDeep, range} from 'lodash';
import DatePicker from '@/shared/components/DatePicker';
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import ClSelect from "@/shared/components/formComponents/ClSelect";
import {formConfig} from "@/config";
import {achievements} from "@/config/descriptions/achievements";
import {achievementsTexts} from "@/config/pageTexts/achievements.json";

export default {
  name: 'EditScheduling',
  components: {
    DatePicker,
    ClFormRow,
    ClSelect
  },
  props: {
    schedulingData: Object,
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      startDate: '',
      endDate: null,
      everyDay: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      everyMonth: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      occurrencesLimitOption: ['Once', 'Repeatedly', 'Daily', 'Weekly', 'Monthly'],
      everyChecked: [],
      formData: {
        scheduleType: '',
        scheduleOccurrencesLimit: null,
        every: [],
        startDate: '',
        onlyAggregateOnActiveDays: false
      },
      fromValidate: null,
      descriptions: {
        achievementAvailableFrom: achievements.list.create.scheduling.achievementAvailableFrom,
        till: achievements.list.create.scheduling.till,
        membersCanAchieveOn: achievements.list.create.scheduling.membersCanAchieveOn
      }
    }
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    every() {
      if (this.formData.scheduleType === 'Daily') return this.arrayToEvery(this.everyDay);
      if (this.formData.scheduleType === 'Monthly') return this.arrayToEvery(this.everyMonth);
      if (this.formData.scheduleType === 'Weekly') return this.weeklyToEvery(range(1, 53));

      return null;
    },
    isEveryVisible() {
      return this.formData.scheduleType === 'Daily' || this.formData.scheduleType === 'Monthly' || this.formData.scheduleType === 'Weekly';
    },
  },
  methods: {
    initialize() {
      this.formData = cloneDeep(this.schedulingData);

      if (this.schedulingData.endDate) {
        const endDate =  new Date(this.schedulingData.endDate);
        this.endDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);
      }

      if (this.schedulingData.startDate) {
        const startDate = new Date(this.schedulingData.startDate);
        this.startDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
      }

      this.everyChecked = this.schedulingData.every.map((item) => {
        return this.every[item - 1]
      });
    },
    handleUpdateLimit(val) {
      if (val > 2147483647) this.formData.scheduleOccurrencesLimit = 2147483647;
      if (!val || val < 1) {
        this.formData.scheduleOccurrencesLimit = 1;
      } else {
        this.formData.scheduleOccurrencesLimit = parseInt(val)
      }
    },
    setStartDate(date) {
      const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      this.formData.startDate = date ? isoDate : "";
      this.startDate = date;
    },
    setEndDate(date) {
      if (!date) return;
      const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      this.formData.endDate = date ? isoDate : null;
      this.endDate = date;
    },
    arrayToEvery(array) {
      return array.reduce((acc, item, index) => {
        return [
          ...acc,
          {
            value: index + 1,
            label: item
          }
        ]
      }, []);
    },
    weeklyToEvery(array) {
      return array.reduce((acc, item) => {
        return [
          ...acc,
          {
            value: item,
            label: `week ${item}`
          }
        ]
      }, []);
    },
    scheduleTypeUpdate({value}) {
      if (value !== 'Once') {
        this.formData.scheduleOccurrencesLimit = 1;
      }
      this.formData.scheduleType = value;
      this.everyChecked = [];
      this.$emit('resetValidate');
    },
    everyDailyCheck(index) {
      if (this.everyEveryChecked.includes(index)) {
        this.everyEveryChecked = this.everyEveryChecked.filter(val => val !== index);
      } else {
        this.everyEveryChecked.push(Number(index))
      }
    },
    disabledStartDate(d) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return d < new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledEndDate(d) {
      const date = this.startDate ? new Date(this.startDate) : new Date();
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return d <= date;
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        if (val.scheduleType === 'Daily' && this.everyChecked.length) {
          val.onlyAggregateOnActiveDays = true;
        }

        if (val.startDate) {
          this.fromValidate = true
        } else {
          this.$emit('resetValidate');
        }

        this.$emit('updateSchedulingData', val);
      },
    },
    everyChecked: {
      deep: true,
      handler: function (val) {
        this.formData.every = val.reduce((acc, item) => {
          return [
            ...acc,
            item.value
          ]
        }, []);
      }
    }
  },
  destroyed() {
    this.$emit('resetValidate');
  }
}
</script>

<style lang="scss">
@import "~@coreui/coreui/scss/coreui";

.create-scheduling {
  height: 100%;
  .create-form {
    height: 100%;
  }
  .form-content {
    background: var(--zq-sub-bg);
    height: 100%;
    .zq--data-picker.mx-datepicker {
      width: 100%;
      min-width: 100%;
    }
  }
  .every {
    display: flex;
    flex-wrap: wrap;
    .every-date-el {
      background: #f1f1f1 none repeat scroll 0 0;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      float: left;
      font-size: 16px;
      line-height: 30px;
      margin: 0 2px 2px 0;
      min-width: 45px;
      padding: 0 3px;
      text-align: center;
    }
    .active {
      background: #e4e3e3 none repeat scroll 0 0;
      border: 1px solid #aaaaaa;
      position: relative;
      &:after {
        content: "+";
        font-size: 11px;
        line-height: 7px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
</style>